<template>
  <div id="container">
    <div class="header">
      <van-nav-bar title="新生报到信息" style="background: #01978a" fixed />
    </div>

    <div class="content">
      <div class="Info">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="Name"
              label="姓名"
              size="large"
              placeholder="姓名"
              required
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="IDcard"
              size="large"
              label="身份证号"
              required
              placeholder="身份证号码"
              :rules="[{ required: true, message: '请填写身份证号' },{validator, message: '身份证号码格式错误！'}]"
            />
            <van-field
              v-model="Mobile"
              size="large"
              label="联系电话"
              required
              :rules="[
              { required: true, message: '请填写您的联系电话！' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
            ]"
            type="tel"
              placeholder="联系电话"              
            />


        <van-field
            readonly
            clickable
            label="到校日期："
            :value="timeValue"
            v-model="arrivalTime"
            required
            placeholder="选择到校日期时间"
            @click="show = true"
            size="large"
          />


        <van-popup
          v-model:show="show"
          position="bottom"
          :style="{ height: '60%' }"
        >
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @cancel="show = false"
            @confirm="confirmPicker"
          />
        </van-popup>





<van-field 
		required 
		readonly 
		clickable 
    size="large"
    v-model="typeTitle"
		label="到校方式" 
		placeholder="请选择到校方式" 

		@click="changePicker(1)" 
		:rules="[{ required: true, message: '请选择到校方式' }]" 
/>

<van-popup  position="bottom" :style="{ height: '50%' }" v-model:show="showPicker1">
<van-picker  cancel-button-text='取消' 
  value-key="title"    
  show-toolbar 
  :columns="TypeList"  
  @confirm="onConfirmPicker"
  @cancel="showPicker1 = false"
  />
</van-popup>






          </van-cell-group>

            <div style="margin: 16px">
          <van-button
            block
            plain
            type="primary"
            style="backgroud: #07c160"
            native-type="submit"
          >
            <h3>提交</h3>
          </van-button>
        </div>
        </van-form>
              
      </div>










      
    </div>
    <div class="footer">
      
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
            ApiUrl: localStorage.getItem("ApiUrl"),
            Name:"",
            IDcard:"",
            Mobile:"",
            arrivalTime: "",
            fileList: [], //默认是一个空数组
            IsOk:false,            
            typeTitle: "",
            typeid: "",
            show: false,
      minDate: new Date(new Date().getFullYear()-5, 1, 1),
      maxDate: new Date(new Date().getFullYear()+5, 1, 1),
      currentDate: new Date(),

            projectNameValue:'', //input框内显示的值
      TypeList: [], //下拉的数据源，从接口中请求到数据，根据需要变成一维数组，只存name
      TypeListALL: [], //下拉的数据源，从接口中请求到数据，根据需要变成一维数组，只存name
      showPicker1: false,
    };
  },
  created() {
    console.log("测试")
    console.log(sessionStorage.getItem("type"))
    document.title = "新生报到系统";
    this.Name=sessionStorage.getItem("Name")
    this.IDcard=sessionStorage.getItem("IDcard")
    this.Mobile=sessionStorage.getItem("Mobile")
    this.arrivalTime=sessionStorage.getItem("arrivalTime")
    this.type=sessionStorage.getItem("type")
    // if(sessionStorage.getItem("IsOk")=="true"){
    //   this.IsOk=true;
    // }
    var A = { id: "1", title: "自行到校" };
    var B = { id: "2", title: "长途汽车" };
    var C = { id: "3", title: "长途汽车" };
    var D = { id: "4", title: "长途汽车" };
    this.TypeListALL.push(A);
    this.TypeListALL.push(B);
    this.TypeListALL.push(C);
    this.TypeListALL.push(D);


       this.TypeListALL.forEach((item) => {
        if(item.id==this.type){
          this.typeTitle = item.title // 中文展示
          this.typeid=item.id
        }
      });

  },
  methods: {

changePicker() { 
  //改变选择框内容
       this.TypeList=this.TypeListALL;
      this.showPicker1 = true
    },
 onConfirmPicker(value) { //选择框消失 赋值
      this.typeTitle = value.title // 中文展示
      this.typeid = value.id // 中文展示
      this.showPicker1 = false
      //this.type = value.id // (this.positionList[index].titleid) // id传给后台
      
    },


    /**
     * 身份证验证
     */
    validator (val) {
      const card15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/
      const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      return card15.test(val) || card18.test(val)
    },
      onSubmit() {



      const forms = new FormData();
      forms.append("Name", this.Name);
      forms.append("IDcard", this.IDcard);
      forms.append("Mobile", this.Mobile);
      forms.append("ArrivalTime", this.arrivalTime);
      forms.append("type", this.typeid);  

      this.$axios
        .post(
          "https://localhost:44330/Admin/Student/API_EditInfo",
          forms,
          {
            headers: {
              "Content-type": "multipart/form-data", // 修改内容类型
            },
          }
        )
        .then((res) => {
          console.log(res);
          //如果传入的响应状态码为0，则成功将文件发送给后台
          if (res.data.code === 0) {
            console.log(res);
            // this.ListData = res.data.data;
            // this.$router.go(-1);
             Toast.success('提交成功！');
             this.$router.push("/test");  
          } else {
            Toast.fail(res.data.msg);
            console.log(res.data.msg); //这块是请求失败后台给返回的相应的数据
          }
        })
        .catch((err) => {
          console.log(err);
          Toast(err);
        });
    },
    // goCheckIn() {
    //   console.log("CheckIn"); 
    //   if(this.IsOk!=true){
    //     Toast.fail("请进行人证核验！")
    //     return;
    //   }     
    //   this.$router.push({
    //       path: "/CheckIn",
    //       query: {
    //         //这里面是写需要传送的值
    //         Name: this.Name,
    //         IDcard: this.IDcard,
    //         Mobile: this.Mobile,
    //       },
    //     });
    // },
    // OutTable() {
    //   if(this.IsOk!=true){
    //     Toast.fail("请进行人证核验！")
    //     return;
    //   }
    //   console.log("OutTable");      
    //   this.$router.push({
    //       path: "/apply",
    //       query: {
    //         //这里面是写需要传送的值
    //         Name: this.Name,
    //         IDcard: this.IDcard,
    //         Mobile: this.Mobile,
    //       },
    //     });
    // },
    // GetResult() {
    //   console.log("GetResult");
    //   if (this.Name && this.IDcard&& this.Mobile) {
    //     sessionStorage.setItem('Name',this.Name)
    //     sessionStorage.setItem('IDcard',this.IDcard)
    //     sessionStorage.setItem('Mobile',this.Mobile)
    //     this.$router.push({
    //       path: "/GetResult",
    //       query: {
    //         //这里面是写需要传送的值
    //         Name: this.Name,
    //         IDcard: this.IDcard,
    //         Mobile: this.Mobile,
    //       },
    //     });
    //   } else {
    //     Toast("请正确填写上方申请人姓名，身份证号码，联系电话！");
    //   }
    // },
    // 显示开始时间
    showPopup() {
      this.show = true;
      this.isLoadingShow = true;
      setTimeout(() => {
        this.isLoadingShow = false;
      }, 500);
    },
    // 确认选择的时间
    confirmPicker(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours()
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      this.className = "timeClass";
      this.arrivalTime = `${year}-${month}-${day} ${hour}:${minute}`
      //this.OutTime = `${year}-${month}-${day}`;
      this.show = false;
    },
    // 选项格式化函数
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      } else if (type === "day") {
        return `${value}日`;
      } else if (type === "hour") {
        return `${value}时`;
      } else if (type === "minute") {
        return `${value}分`;
      } else if (type === "second") {
        return `${value}秒`;
      }
      return value;
    },



    onClickLeft() {
      console.log("返回");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  /* height: 6vh; */
  /* color: #946afe; */
  font-size: 2vh;
  margin-left: 3vw;
  color: black;
}

.content {
  margin-top: 6vh;
  padding-top: 4vh;
  min-height: 94vh;
  height: 100%;
  background-color: #f1f1f3;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}
.wrapper {
  margin: 10px;
  /* 声明一个容器 */
  display: grid;
  /*  声明列的宽度  */
  grid-template-columns: repeat(1, 98%);
  /*  声明行间距和列间距  */
  grid-gap: 10px;
  /*  声明行的高度  */
  grid-template-rows: 150px 150px 150px;
  margin-top: 20px;
}
.van-divider{
  color: blue;
}

.header /deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 16px;
}
.header /deep/ .van-nav-bar .van-icon {
  color: white !important;
}
.item {
  text-align: center;
  font-size: 100%;
  color: rgb(2, 2, 2);
  border-radius: 10px;
  background-color: #946afe;
}
.itemCenter {
  margin-top: 40px;
}
</style>